<div class="w-100 b-sm">
  <div class="inner-head">
    <div class="back-icon">
      <a (click)="backhome()" class="sldlsd"
        ><span class="glyphicon glyphicon-arrow-left"></span
      ></a>
    </div>
    <div class="head-content">
      <h5>Municipal Laws</h5>
    </div>
  </div>
</div>
<!-- w-100 end -->
<div class="container-fluid common-container p-0 center law-page">
  <!-- <br /><br /><br /> -->
  <ng-container>
    <!-- {{tempStates | json}} -->
    <!-- {{keys | json}} -->
    <!-- {{list | json}}  -->
    <div class="row m-1 mb-4" *ngIf="!compareState || compareState == 0">
      <div class="col-md-6 mt-2" style="min-height: 50vh">
        <carousel
          class="municipal-law-carousel"
          [noPause]="false"
          (activeSlideChange)="onSlideChaning($event)"
        >
          <slide *ngFor="let item of slides" title="Click to compare states">
            <!-- (click)="showStateGroup(item)" -->
            <!-- {{ item | json }} -->
            <!-- <div></div> -->

            <!-- <img [src]="item.imgUrl" /> -->
            <div class="carousel-caption d-none d-md-block">
              <h4 class="title" style="font: 1.5em">{{ item.caption }}</h4>
            </div>
          </slide>
          <div id="finance-law-map"></div>
        </carousel>
        <br />
      </div>

      <div class="col-md-6 mt-2">
        <button
          class="mobile-btn-center searchButton"
          (click)="showStateSelectionSection()"
        >
          Compare States
        </button>

        <!--        <div class="col-md-12">-->
        <h3 class="title">Comparison of Municipal Finance Provisions</h3>
        <!--          <br>-->
        <!--        </div>-->
        <br />
        <p class="lowerText">
          Presented here is a comparison of the pronouncements of various
          Municipal Acts, underlying Rules and Regulations, and Accounts and
          Budget Manuals of different Indian states so far as they pertain to
          aspects of finance, accounts, budget, audit, financial disclosure and
          so on in municipalities.
        </p>
        <p class="lowerText">One will find answers to questions such as:</p>

        <ul class="ulListText">
          <li>What is the system of accounting prescribed?</li>
          <li>What is the timeline for presenting the municipal budget?</li>
          <li>
            Is a budget calendar prescribed? if so, where is it prescribed?
          </li>
          <li>What are the limits on municipal borrowing?</li>
          <li>
            Do the laws require citizen participation in budget preparation?
          </li>
        </ul>
        <p class="lowerText">
          The comparison attempts to answer a total of 70 questions across 13
          criteria. Answers have been kept short and precise. Where found
          necessary, additional explanations have been provided for better
          understanding as a pop-up which can be seen upon hovering the mouse
          pointer over the answer. Reference to the specific provision of the
          Act/Rule/Manual is also given wherever possible for the benefit of
          anyone desirous of doing a deep-dive.
        </p>
      </div>
    </div>

    <div class="row container m-auto mt-2" *ngIf="compareState == 1">
      <div class="col-md-12">
        <h3>
          Comparison of Municipal Finance Provisions
          <a
            *ngIf="compareState > 0"
            class="back-link n-sm back-btns"
            style="margin-right: 0px"
            (click)="compareState = 0"
            >Back</a
          >
        </h3>
      </div>
      <div class="col-md-12 n-sm">
        <button
          *ngIf="selectedStates.length >= 1"
          (click)="clearSelectedStates()"
          class="btn btn-sm mb-1 btn-danger clearButton"
        >
          Clear All
        </button>
      </div>
      <div class="row m-law-container">
        <div
          *ngFor="let state of states"
          class="col-sm-6 col-xs-12 col-md-3 col-lg-3 state-list m-law"
          (click)="addToCompare(state)"
        >
          <div
            class="stateSearchButton my-2"
            [ngClass]="{ 'btn-selected': state.selected }"
          >
            {{ state.name }}
          </div>
        </div>
      </div>
      <div class="col-md-12 sm-p0">
        <br />
        <div class="col-md-3 col-md-offset-3 col-xs-4 mb-2">
          <button
            class="f10-sm btn-sm searchButton"
            (click)="showComparisionPage()"
            [tooltipEnable]="selectedStates.length < 2"
            tooltip="Select atleast 1 states"
          >
            View and Compare
          </button>
        </div>
        <div class="col-md-3 col-xs-6 mb-2">
          <button
            class="btn btn-danger btn-sm col-md-12 f10-sm center clearButton"
            (click)="compareAllStates()"
          >
            Compare All
          </button>
        </div>
        <div class="col-md-3 col-xs-2 mb-2 b-sm">
          <button
            *ngIf="selectedStates.length > 1"
            (click)="clearSelectedStates()"
            class="btn btn-sm mb-1 btn-danger right"
          >
            Clear All
          </button>
        </div>
      </div>
      <!-- {{selectedStates | json}} -->
    </div>

    <div
      class="row container accordion-full-m m-auto"
      *ngIf="compareState == 2"
    >
      <div class="col-md-12 col-sm-12 accordion-full-m">
        <!-- <h3>State comparision -->

        <!-- <a class="back-link" style="margin-top: 100px;" (click)="backToStateSelection()">Back</a> -->
        <!-- <a *ngIf="compareState > 0" class="back-link" (click)="backToStateSelection()">Back</a> -->
        <!-- </h3> -->

        <!--*ngIf="selectedStates.length > 1"-->
        <!-- {{list | json}} -->
        <!-- col end -->
        <div class="col-md-12 d-flex col-sm-12 mt-3">
          <button
            (click)="openStateSelectionModal(template)"
            class="btn btn-warning back-link clearButton"
          >
            Compare States
          </button>
          <!-- <br /> -->
          <a>
            <button
              class="btn btn-danger back-link searchButton"
              (click)="download()"
            >
              Download
            </button>
          </a>
          <!-- <br /> -->
          <a class="back-link n-sm" (click)="showMapView()">Back</a>
        </div>
        <!-- col end -->

        <div class="table-responsive mt-3 col-12">
          <table
            class="table table-striped table-hover text-center"
            border="1"
            id="compare-table"
            [ngStyle]="{ width: tableWidth }"
          >
            <tr>
              <th
                *ngFor="let key of selectedStates"
                style="text-transform: capitalize"
                [ngStyle]="{ width: tdWidth }"
                class="headTable"
              >
                {{ key }}
              </th>
            </tr>
          </table>

          <accordion
            [closeOthers]="true"
            class="fixed-header"
            [ngStyle]="{ width: tableWidth }"
          >
            <accordion-group *ngFor="let key of keys" class="table-responsive">
              <h4 accordion-heading>
                {{ messages[key] }} <span class="fa fa-angle-down"></span>
              </h4>
              <div class="scrolling outer">
                <div class="inner">
                  <!-- mytable -->
                  <table
                    class="table table-striped accordion-body-table law-table-container"
                  >
                    <tr *ngFor="let title of structure[key]">
                      <td
                        *ngFor="let key of selectedStates; let i = index"
                        [ngStyle]="{ width: tdWidth }"
                      >
                        <div *ngIf="i == 0">
                          <div *ngIf="messages[title]">
                            <b class="lowerText">{{ messages[title] }}</b>
                          </div>
                          <div *ngIf="!messages[title]" class="lowerText">
                            {{ title }}
                          </div>
                        </div>
                        <div *ngIf="i > 0">
                          <div *ngIf="!(list[key] && list[key][title])">
                            No information available
                          </div>
                          <i
                            *ngIf="
                              list[key] &&
                              list[key][title] &&
                              list[key][title].tooltip
                            "
                            [tooltip]="list[key][title].tooltip"
                            placement="bottom"
                            class="glyphicon glyphicon-info-sign"
                          ></i>

                          <div
                            *ngIf="
                              list[key] &&
                              list[key][title] &&
                              list[key][title].titleWithCaptions
                            "
                          >
                            <div
                              *ngFor="
                                let cell of list[key][title].titleWithCaptions;
                                let i = index
                              "
                            >
                              <div class="ulListText">
                                <span
                                  *ngIf="
                                    list[key][title].titleWithCaptions.length >
                                    1
                                  "
                                  >{{ i + 1 }}.</span
                                >
                                <span *ngIf="cell.titles">
                                  <div
                                    *ngFor="let item of titles"
                                    class="lowerText"
                                  >
                                    {{ item }}
                                  </div>
                                </span>
                                <span *ngIf="cell.title" class="lowerText">{{
                                  cell.title
                                }}</span>
                                <span class="text-primary" class="lowerText">{{
                                  cell.caption
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="
                              list[key] &&
                              list[key][title] &&
                              list[key][title].titleThenCaptions
                            "
                          >
                            <div
                              *ngFor="
                                let cell of list[key][title].titleThenCaptions
                              "
                            >
                              <div class="lowerText">{{ cell.title }}</div>
                              <div class="text-primary" class="lowerText">
                                {{ cell.caption }}
                              </div>
                            </div>
                          </div>

                          <div
                            *ngIf="
                              list[key] &&
                              list[key][title] &&
                              !(
                                list[key][title].titleWithCaptions ||
                                list[key][title].titleThenCaptions
                              )
                            "
                          >
                            <!-- {{list[key][title] | json}} -->
                            {{ list[key][title].title }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<!-- <br><br><br> -->

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add / Remove states from comparison</h4>
    <button
      type="button"
      style="width: 30%"
      class="pull-right n-sm stateSearchButton"
      stlye="margin-right:2% !important;"
      (click)="clearSelectedStates()"
    >
      Clear All
    </button>
    <button
      type="button"
      class="close pull-right mt-1"
      aria-label="Close"
      (click)="modalRef.hide()"
      style="margin-left: 2%"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="mv-65 my-2 d-flex flex-wrap">
        <div
          *ngFor="let state of states"
          class="col-sm-6 col-xs-12 col-md-3 col-lg-3 state-list"
          (click)="addToCompare(state)"
          style="width: auto"
        >
          <div
            class="stateSearchButton mx-2 my-2"
            [ngClass]="{ 'btn-selected': state.selected }"
          >
            {{ state.name }}
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6">
        <br />
        <button
          [tooltipEnable]="selectedStates.length > 1"
          [tooltip]="'Select 1 or more states'"
          class="center searchButton"
          (click)="selectedStates.length > 1 ? modalRef.hide() : null"
        >
          Apply Selection
        </button>
      </div>
      <div class="col-md-6 col-xs-6 b-sm">
        <br />
        <button
          type="button"
          class="btn btn-warning center b-sm"
          stlye="margin-right:2% !important;"
          (click)="clearSelectedStates()"
        >
          Clear All
        </button>
      </div>
    </div>
    <!-- row end -->
  </div>
  <!-- modal body end -->
</ng-template>
