<div class="container-fluid">
    <!-- <div class="row header mt-2">
        <div class="col-sm-12 col-lg-12 col-md-12 header2">
            <span>Overview</span>
        </div>
    </div> -->
    <div class="row mb-3 mt-3">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body o-text">
                    {{overviewText}}
                </div>
            </div>
        </div>
        <!-- <button class="demoBtn">View Demo</button> -->
    </div>
    <div class="row" id="myIdentifier" #myIdentifier>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 h-p">
                            Requirements for 15th FC Grants for FY 2022-23
                        </div>
                        <div class="col-sm-12 a-s d-none">
                            Application Status -{{status}}
                        </div>
                        <div class="col-sm-12 mt-2 d-none">
                            <div class="progressBar">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" [ngStyle]="{ width: percentage + '%' }" aria-valuemax="100">
                                        {{percentage.toFixed(0)}}%</div>
                                </div>
                            </div>
                        </div>
                        <div class="container-cnt">
                            <div class="row titleCards w-100">
                                <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!cardFit">
                                    <!-- [ngStyle]="{'width': width+'px'}" -->
                                    <div class="row" style="">
                                        <div class="col-sm" style="width: 20%;" [ngClass]="{'d-n' : (index >= 5)}" *ngFor="let slide of cardsOverview; let index=index">
                                            <!-- <div *ngIf="(isMillionPlus =='No') && "> -->
                                            <!-- [style.margin-right]="(numcard == 5) ? '9%' : ''" -->
                                            <div *ngIf="index < 5">
                                                <a [routerLink]="'../' + slide?.link">
                                                    <div class="card  individualCard" style="" [style.margin-bottom]="index != val ? '42px' : '0'" (mouseenter)="onHover(index, cardsOverview[index].title, cardsOverview[index].key, slide)" (mouseleave)="onUnhover(index)" [ngStyle]="{'background-image':
          index == 0 ?' linear-gradient(#73C557, #3A632C)': index == 1  ? 'linear-gradient(#42C9F6, #21657B)': index == 2 ?
          'linear-gradient(#F16831, #793419)': index == 3 ? 'linear-gradient(#FDCB2E, #7F6617)' :
          index == 4 ? 'linear-gradient(#549D5E, #2A4F2F)' : index == 5 ? 'linear-gradient(#E71368, #740A34)' : index == 6 ?
          'linear-gradient(#9D198B, #4F0D46)' : 'linear-gradient(#73C557, #3A632C)' }">
                                                        <img [src]="slide?.image | toStorageUrl" height="22" width="27" />
                                                        <div>
                                                            <!-- class="card-body" -->
                                                            <h5 class="card-title"> {{slide['title']}} </h5>

                                                        </div>
                                                    </div>

                                                </a>
                                            </div>

                                            <!-- </div> -->

                                            <!-- <svg height="42px" width="auto" [style.display]="index == val ? 'block' : 'none'">
                                          <line [attr.x1]="65" y1="0px " [attr.x2]="65" y2="42px " style="stroke-width:2;"
                                              [ngStyle]="{ 'stroke': index==0 ? '#73C557': index==1 ? '#42C9F6': index==2 ?
                                '#F16831': index==3 ? '#549D5E' : index==4? '#FDCB2E': index==5? '#E71368' : index==6 ?
                                '#9D198B' : '#73C557' } " />
                                          <svg height="30 " width="auto">
                                              <circle [attr.cx]="65" cy="42 " r="12 " stroke="white " stroke-width="10 "
                                                  [ngStyle]="{ 'fill': index==0 ? '#73C557': index==1 ? '#42C9F6': index==2 ? '#F16831':
                                    index==3 ? '#549D5E' : index==4 ? '#FDCB2E': index==5 ? '#E71368' : index==6 ? '#9D198B' : '#73C557' } " />
                                          </svg>
                                            </svg> -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="message " [ngStyle]="{ 'background-image': i==1 ? 'linear-gradient(#73C557, #3A632C)' :
                            i==2 ? 'linear-gradient(#42C9F6, #21657B)' : i==3 ? 'linear-gradient(#F16831, #793419)': i==4 ?
                            'linear-gradient(#FDCB2E, #7F6617)' : i==5?
                            'linear-gradient(#549D5E, #2A4F2F)': i==6? 'linear-gradient(#E71368, #740A34)' : i==7 ? 'linear-gradient(#9D198B, #4F0D46)' : 'linear-gradient(#73C557, #3A632C)' } ">
                                    {{message}}
                                </div>
                            </div>
                        </div>
                        <div class="container-cnt">
                            <div class="row titleCards w-100">
                                <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!cardFit">
                                    <!-- [ngStyle]="{'width': width+'px'}" -->
                                    <div class="row">
                                        <div class="col-sm-3" [ngClass]="{'d-n' : (index < 5)}" *ngFor="let slide of cardsOverview; let index=index">
                                            <!-- <div *ngIf="(isMillionPlus =='No') && "> -->
                                            <!-- [style.margin-right]="(numcard == 5) ? '9%' : ''" -->
                                            <div *ngIf="index >= 5">
                                                <a [routerLink]="'../' + slide?.link">
                                                    <div class="card  individualCard" (mouseenter)="onHover(index, cardsOverview[index].title, cardsOverview[index].key, slide)" (mouseleave)="onUnhover(index)" [ngStyle]="{'background-image':
          index == 0 ?' linear-gradient(#73C557, #3A632C)': index == 1  ? 'linear-gradient(#42C9F6, #21657B)': index == 2 ?
          'linear-gradient(#F16831, #793419)': index == 3 ? 'linear-gradient(#FDCB2E, #7F6617)' :
          index == 4 ? 'linear-gradient(#549D5E, #2A4F2F)' : index == 5 ? 'linear-gradient(#E71368, #740A34)' : index == 6 ?
          'linear-gradient(#9D198B, #4F0D46)' : 'linear-gradient(#73C557, #3A632C)' }">

                                                        <img [src]="slide?.image | toStorageUrl" height="22" width="27" />
                                                        <div>
                                                            <!-- class="card-body" -->
                                                            <h5 class="card-title"> {{slide['title']}} </h5>

                                                        </div>
                                                    </div>

                                                </a>
                                            </div>

                                            <!-- </div> -->

                                            <!-- <svg height="42px" width="auto" [style.display]="index == val ? 'block' : 'none'">
                                <line [attr.x1]="65" y1="0px " [attr.x2]="65" y2="42px " style="stroke-width:2;"
                                    [ngStyle]="{ 'stroke': index==0 ? '#73C557': index==1 ? '#42C9F6': index==2 ?
                      '#F16831': index==3 ? '#549D5E' : index==4? '#FDCB2E': index==5? '#E71368' : index==6 ?
                      '#9D198B' : '#73C557' } " />
                                <svg height="30 " width="auto">
                                    <circle [attr.cx]="65" cy="42 " r="12 " stroke="white " stroke-width="10 "
                                        [ngStyle]="{ 'fill': index==0 ? '#73C557': index==1 ? '#42C9F6': index==2 ? '#F16831':
                          index==3 ? '#549D5E' : index==4 ? '#FDCB2E': index==5 ? '#E71368' : index==6 ? '#9D198B' : '#73C557' } " />
                                </svg>
                                  </svg> -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row mt-4 mb-3">
        <div class="col-sm-6 d-none">
            <button class="btn btn-c-back" [routerLink]="backRouter"><- Previous Form</button>
        </div>
        <div class="col-sm-12 text-end">
            <button class="btn btn-c-next ms-3" [routerLink]="nextRouter">Next Form -></button>
        </div>
    </div>
</div>
