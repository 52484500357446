<div class="w-100">
  <div class="wrap-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" style="margin: auto;">
          <div class="ds-i-c mt-4 mt-lg-5">
            <span class="city">city</span>
            <span class="fy">finance.in</span>
          </div>
          <div class="ds-i-div">
            <span class="ds-info">Standardised, timely and credible financial information for

              <span class="cities-count" [countUp]="dataForVisualization?.coveredUlbCount ? dataForVisualization?.coveredUlbCount : 3000" [options]="{startVal: 3000, duration: 5}">0
              </span>+ cities of India </span>
          </div>
          <div class="g-filter-d">
            <form class="filter-form w-100">
              <input type="text" placeholder="Search for City or State" matInput [formControl]="globalFormControl"
                [matAutocomplete]="auto" class="fl-input w-80" />
              <mat-autocomplete #auto="matAutocomplete">
                <!-- [routerLink]="[
                              '/dashboard/' + option?.type + '/' + option?._id]" -->
                <a (click)="dashboardNav(option)" *ngFor="let option of filteredOptions; let i = index"
                  style="text-decoration: none">
                  <mat-option [ngClass]="{ firstCls: i == 0 }" [value]="option?.name">
                    <div class="row">
                      <div class="col-8">
                        <span> {{ option?.name }}</span>
                      </div>
                      <div class="col-3">
                        <span style="color: #8e8e8e">{{
                          option.type == "ulb"
                          ? option.ulbType.name
                          : (option.type | titlecase)
                          }}</span>
                      </div>
                    </div>
                  </mat-option>
                </a>
                <mat-option *ngIf="noDataFound" class="noData firstCls">
                  Sorry, we can’t find anything related to your search. Try searching for a city or state
                </mat-option>
              </mat-autocomplete>
              <button type="button" class="btn lnd-btn" (click)="globalSearchClick()">
                Search
              </button>
            </form>
          </div>
          <div class="row mt-4">
            <div class="col-lg-auto col-sm-12">
              <span class="rec-text">Recent Searches:</span>
            </div>
            <div class="col">
              <div class="ser-chip" *ngFor="let item of recentSearchArray">
                <a style="text-decoration: none" (click)="dashboardNav(item)" class="centerSearchedValues">
                  <span class="rec-text">{{ item?.name }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Commnted and given display: none for don't show ministers images -->
        <!-- <div class="col"></div> -->
        <div class="col-lg-auto" style="display: none;">
          <div class="images" style="text-align: -webkit-center">
            <carousel [interval]="myInterval" [isAnimated]="true" (activeSlideChange)="carouselClass($event)"
              [ngClass]="{ 'p-indi': p_indi, 'm-indi': m_indi }">
              <slide *ngFor="let slide of slides; let index = index">
                <img [src]="slide.image" alt="image slide" [ngClass]="slide.class" />
                <div class="cr-text" [ngClass]="slide.textCls">
                  <div class="sl-text">{{ slide.text }}</div>
                  <div class="sl-name">{{ slide.name }}</div>
                  <div class="sl-des">{{ slide.designation }}</div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row wrap-card">
      <div class="col-lg-12 col-md-12 col-xs-12">
        <div class="new-card-text">
          <!-- Removed the count and changed the text now -->
          <!-- <h3 class="w-new">What’s New ({{ whatNewData.length }})</h3> -->
          <h3 class="w-new">In the Spotlight</h3>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-xs-12">
        <div class="card-d">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
            (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let item of whatNewData" class="slide card-img">
              <div style="height: 200px">
                <img [src]="item.imageUrl" alt="" class="image" width="50%" height="100%" />
              </div>
              <a class="card-text new-card-l" href="{{ item.downloadUrl}}" target="_blank">{{ item.name }}</a>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mr-t-b factsheet-bg" style="display: none;">
    <div class="h-p2 text-center mb3">
      <h2 class="h-sl">Factsheet</h2>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-4 g-0 mb-4" #highlightContainer>
      <div class="img-ulb col-md-3">
        <img src="../../../assets/images/Fact-sheet-left.png" class="rounded">
      </div>
      <div class="col-md-9 ps-3">
        <ul class="autoplay-items">
          <li [ngClass]="{'active': highlightNo === 1, 'done' : highlightNo > 1}" (click)="highlightNo = 1">
            <h3>3+ Years
              <span class="small-desc">of aggregating financial information on ULBs of India</span>
            </h3>
            <!-- <div class="sl-sub-text">One or more financial statement(s) from 4507 ULBs (95%) available for FY 2019-20 to FY 2021-22 </div> -->
          </li>
          <li [ngClass]="{'active': highlightNo === 2, 'done' : highlightNo > 2 }" (click)="highlightNo = 2">
            <h3>10,862
              <span class="small-desc">standardised Financial Statements available</span>
            </h3>
            <!-- <div class="sl-sub-text">One or more financial statement(s) from 3175 ULBs (67%) available in standardised format for FY 2019-20 to FY 2021-22 </div> -->
          </li>
          <li [ngClass]="{'active': highlightNo === 3, 'done' : highlightNo > 3 }" (click)="highlightNo = 3">
            <h3>₹ 65,706 Crore
              <span class="small-desc">XV Finance Commission grants
                released to 4,600+ ULBs</span>
            </h3>
            <!-- <div class="sl-sub-text">₹ 1,08,916 Crore grants being administered for 4,700 ULBs through <a href="https://cityfinance.in/">cityfinance.in</a></div> -->
          </li>
          <li [ngClass]="{'active': highlightNo === 3, 'done' : highlightNo > 4 }" (click)="highlightNo = 4">
            <h3>₹ 1,08,916 Crore
              <span class="small-desc">grants being administered for 4,700 ULBs</span>
            </h3>
            <!-- <div class="sl-sub-text">₹ 1,08,916 Crore grants being administered for 4,700 ULBs through <a href="https://cityfinance.in/">cityfinance.in</a></div> -->
          </li>
        </ul>
        <div class="text-right mr-2" style="color: #fff;">Last updated on: 1/3/24, 2:43 PM</div>
      </div>
    </div>
  </div>
  <div class="factsheet" style="display: none;">
    <div class="container">
      <h3>Factsheet</h3>
      <div class="mb-3">
        <div class="row">
          <div class="col-4">
            <div class="fact-card">
              <div class="fact-count">3+ Years</div>
              <div class="fact-text">of aggregating financial information on ULBs</div>
            </div>
          </div>
          <div class="col-4">
            <div class="fact-card">
              <div class="fact-count">7 Years</div>
              <div class="fact-text">of financial data available </div>
            </div>
          </div>
          <div class="col-4">
            <div class="fact-card">
              <div class="fact-count">10,862</div>
              <div class="fact-text">standardised Financial Statements available</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="fact-card">
            <div class="fact-count">₹ 1,08,916 Crore</div>
            <div class="fact-text">XV Finance Commission grants being administered</div>
          </div>
        </div>
        <div class="col-6">
          <div class="fact-card">
            <div class="fact-count">₹ 65,706 Crore</div>
            <div class="fact-text">XV Finance Commission grants released to 4,600+ ULBs</div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="container-fluid">
    <div class="row map-bg">
      <app-dashboard-map-section></app-dashboard-map-section>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="res-h">Discover</div>
        <div class="res-s-h pt-2">
          Delve into key aspects of municipal data summarized and presented in a
          user-friendly manner
        </div>
        <div class="container">
          <div class="row mt-3">
            <div class="col-lg-4 col-md-6 col-sm-12 text-center"
              *ngFor="let cardItem of exploreCardData; let i = index">
              <div class="card card1 mx-auto">
                <div class="cd-icon">
                  <img [src]="cardItem.icon" alt="" />
                </div>
                <div class="cd-head">
                  {{ cardItem.label }}
                </div>
                <div class="cd-s-head">
                  {{ cardItem.text }}
                </div>
                <!-- <div class="hidden-text">
                                    {{cardItem.hiddenText}}
                                </div> -->
                <div class="hidden-text btn-d">
                  <button [routerLink]="cardItem.link" class="btn btn-view">
                    Know More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="com-logo">
    <div class="container">
      <div class="row mt-2">
        <div class="container">
          <div class="row mt-3">
            <div class="col-md-6 col-12 col-lg-6 pb-4">
              <div class="col-12 d-flex flex-wrap">
                <div class="col-4 r-brdr">
                  <p class="text-center partner-title">
                    Conceived & Managed by
                  </p>
                  <div class="d-flex justify-content-center align-items-center pt-2">
                    <a href="https://www.janaagraha.org/home/" target="_blank">
                      <!-- <img
                        src="../../../assets/new_dashBord_ftr_hdr/logo-for-web1/logo-for-web1.png"
                        alt=""
                        class="img-fluid"
                      /> -->
                      <img src="../../../assets/M FIGMA/janagraha 1.png" alt="" class="img-fluid">
                    </a>
                  </div>

                </div>

                <div class="col-4 r-brdr">
                  <p class="text-center partner-title">Knowledge Partner</p>
                  <div class="d-flex justify-content-center align-items-center pt-2">
                    <a href="https://www.magc.in/" target="_blank">
                      <img style="height:30px" src="../../../assets/new_dashBord_ftr_hdr/magc.png" alt=""
                        class="img-fluid" />
                    </a>

                  </div>

                </div>

                <div class="col-4 r-brdr">
                  <p class="text-center partner-title">Technology Partner</p>
                  <div class="d-flex justify-content-center align-items-center text-center">
                    <a href="https://dhwaniris.com/" target="_blank">
                      <img width="75%"
                        src="../../../assets/new_dashBord_ftr_hdr/Dhwani-Logo_Versions-e1533798168980/dhLogo.png" alt=""
                        class="img-fluid" />
                    </a>

                  </div>

                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 col-lg-6 d-flex flex-wrap pb-4">
              <div class="col-12 px-0 d-flex flex-wrap">
                <p class="text-center col-12 partner-title">Supported by</p>
                <div class="col-3 d-flex justify-content-center align-items-center r-brdr">
                  <a href="https://www.omidyarnetwork.in/" target="_blank">
                    <img src="../../../assets/new_dashBord_ftr_hdr/Omidyar_Network_logo.png" alt="" class="img-fluid" />
                  </a>
                  <!-- <div class="vr"></div> -->
                </div>

                <div class="col-3 d-flex justify-content-center align-items-center r-brdr">
                  <a href="https://www.gatesfoundation.org/" target="_blank">
                    <img src="../../../assets/new_dashBord_ftr_hdr/BMGF.png" alt="" class="img-fluid" />
                  </a>
                  <!-- <div class="vr"></div> -->
                </div>

                <div class="col-3 d-flex justify-content-center align-items-center ps-3 r-brdr">
                  <a href="https://crdf.org.in/center/cwas" target="_blank">
                    <!-- <img
                      src="../../../assets/new_dashBord_ftr_hdr/shakti-logo/shakti-logo.png"
                      alt=""
                      class="img-fluid"
                    /> -->
                    <img src="../../../assets/white logo stacked june 22 v3.png" alt="" width="80%" class="img-fluid" />
                  </a>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-center ps-3">
                  <a href="https://www.worldbank.org" target="_blank">
                    <img src="../../../assets/world-bank-logo.svg" alt="" width="100%" class="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>