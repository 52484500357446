
<div class="wrapper app-wrapper" [ngClass]="isEmbedModeEnable ? 'mt-4' : ''">
    <!-- <app-home-header></app-home-header> -->
    <app-n-home-header *ngIf="!isEmbedModeEnable"></app-n-home-header>

    <router-outlet></router-outlet>
</div>
<app-footer *ngIf="!isEmbedModeEnable"></app-footer>

<div class="spinner" *ngIf="showLoader" style="z-index: 999999; display: flex; flex-direction: column;  position: fixed; top: 0px; bottom: 0px">
    <div class="row">
        <mat-spinner></mat-spinner>
    </div>
    <div class="row">
        <span class="spinText">Loading...</span>
    </div>
</div>
