<div class="container" *ngIf="!isApiInProgress">
    <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6">
            <h4 class="form-header">
                {{isGfcOpen ? 'Garbage Free City (GFC)' : 'Open Defecation Free (ODF)'}}
            </h4>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 textEnd">
            <button type="button" class="btn btn-primary" id="prevBtn" (click)="preview()">Preview</button>
        </div>
    </div>
    <form [formGroup]="profileForm" class="formShadow" [ngClass]="{'disabled-form': isDisabled}">
        <div class="row mt-4 mb-4 question">
            <div class="col-sm-6 col-lg-6 col-md-6 q-s">
                <span class="fontStyle">
                    (A) {{isGfcOpen ? 'Garbage Free City (GFC)' : 'Open Defecation Free (ODF)'}} Rating ?<sup class="text-danger">*</sup>
                </span>
            </div>
            <div class="col-sm-6 col-lg-6 col-md-6 q-s">
                <select [ngClass]="{ 'is-invalid': submitted && f.rating.errors }" formControlName="rating" class="selectDropdown form-control" (change)="onChange($event.target.value)">
                    <option value="" selected disabled>Select Rating</option>
                    <option *ngFor="let item of ratings" [value]="item._id">{{ item.name }}</option>
                  </select>

                <div *ngIf="submitted && f.rating.errors" class="text-danger">
                    <div *ngIf="f.rating.errors.required">Rating is required</div>
                </div>

                <!-- <div *ngIf="!isGfc"> -->
                <div class="score">
                    <div>Score = {{ratingMark}}</div>
                </div>
                <!-- </div> -->

            </div>
        </div>
        <div class="row mb-4 question marginClass">
            <div class="col-sm-6 col-lg-6 col-md-6 q-s">
                <span class="fontStyle" *ngIf="uploadCertificate">
                (B) Upload {{isGfcOpen ? 'GFC' : 'ODF'}} Certificate?<sup class="text-danger">*</sup>
            </span>
                <span class="fontStyle" *ngIf="uploadDeclaration">(B) Upload Declaration ?<sup class="text-danger">*</sup>
            </span>
            </div>
            <div class="col-sm-6 col-lg-6 col-md-6 q-s">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-5">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-4 image-upload">
                                <label for="ipt">
                        <a class="btn btnUpload" (click)="uploadButtonClicked('A')"
                          >Upload
                          pdf
                          <img
                            src="../../../../assets/form-icon/upload-pdf-icon.png"
                            alt="" style="margin-left: 4px;">
                          </a>
                          <p class="errorMessege">{{errorMessege}}</p>
                          </label>
                                <input #ipt id="ipt" [ngClass]="{ 'is-invalid': submitted && f?.cert?.controls?.url?.errors }" name="cin1" type="file" (change)="fileChangeEvent($event, 'odfProgress', 'odfFileName')" accept=".pdf" />
                                <div>
                                    <div *ngIf="submitted && f?.cert?.controls?.url?.errors" class="customDate text-danger">
                                        <div *ngIf="f?.cert?.controls?.url?.errors.required">File is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showIcon">
                <div class="col-sm-6 col-md-6 col-lg-6 filePadding mb-3">
                    {{odfFileName}}
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 progressBar" *ngIf="odfProgress">
                    <div class="progress-bar" *ngIf="odfProgress" role="progressbar" attr.aria-valuenow="{{ odfProgress }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: odfProgress + '%' }">
                        {{ odfProgress }}%
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 filePadding">
                    <a [href]="odfUrl | toStorageUrl" target="_blank" style="pointer-events: all;">
                        <img src="../../../../assets/form-icon/download-pdf-icon.png" alt="">
                    </a>
                    <span style="cursor:not-allowed;">
            <a (click)="clearFile()"
              style="margin-left: 4px;">
              <img src="../../../../assets/form-icon/close-red-icon.png" alt=""
              >
            </a>
        </span>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4 question" *ngIf="uploadCertificate">
            <div class="col-sm-6 col-lg-6 col-md-6 q-s">
                <span class="fontStyle">(C) Certification Issue Date?<sup class="text-danger">*</sup>
       </span>
            </div>
            <!-- onfocus="this.max=new Date().toISOString().split('T')[0]" -->
            <div class="col-sm-6 col-lg-6 col-md-6 q-s">
                <input [ngClass]="{ 'is-invalid': submitted && f.certDate.errors }" type="date" name="cerDate" (change)="dateChange()"
                 placeholder="DD-MM-YYYY" class="inputDate form-control" [min]="minDate" formControlName="certDate" (keydown)="disableEnterDate()" [max]="maxDate">
                <!-- <input class="inputDate form-control" (change)="dateChange()"
                [ngClass]="{ 'is-invalid': submitted && f.certDate.errors }"
                formControlName="certDate" placeholder="DD-MM-YYYY" bsDatepicker [minDate]="minDate"
                [maxDate]="today"> -->

                <div>
                    <div *ngIf="submitted && f.certDate.errors" class="text-danger">
                        <div *ngIf="f.certDate.errors.required">Date is required
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">
    <div class="col-sm-6">
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6 previousText">
    </div>
</div> -->
    </form>
    <!-- <div class="row mt-3" *ngIf="commonActionCondition">
        <div class="col-sm-12">
            <app-common-action [item]="errorSend" (newItemEvent)="outputData($event)" [stateReturn]="stateReturn" [stateApprove]="stateApprove"></app-common-action>
        </div>
    </div> -->
    <!-- action components -->
    <div class="row mt-3 mb-3">
        <div class="col-sm-12">
            <app-common-action [actionRes]="actionRes" [formData]="actFormData" [actionFor]="'ULBForm'" [actBtnDis]="actionBtnDis" (actionEventEmit)="actionData($event)" [canTakeAction]="canTakeAction" [commonActionError]="actionError" [formNamefiles]="formName">
            </app-common-action>
        </div>
    </div>
    <div class="row mb-3 mt-4">
        <div class="col-sm-7 col-md-7 col-lg-7">
            <button type="button" class="btn btn-c-back" [routerLink]="backRouter"><- Previous Form</button>
        </div>
        <div class="col-sm-5 col-md-5 col-lg-5 text-end">
            <div class="row" [ngClass]="{'positionDraft' : positionDraft}">
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <button type="button" *ngIf="userData?.role == 'ULB'" [disabled]="isDisabled" class="btn btnDraft" (click)="onDraft()">Save as Draft</button>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <button type="submit" [disabled]="isDisabled" *ngIf="userData?.role == 'ULB'" (click)="alertFormFinalSubmit()" class="btn btnDraft">Submit</button>
                    <span *ngIf="canTakeAction">
                      <button type="submit" [disabled]="actionBtnDis" *ngIf="userData?.role == 'STATE' || userData?.role == 'MoHUA'"
                      (click)="saveAction()" class="btn btnDraft">Submit</button>
                    </span>

                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <button type="button" class="btn btn-c-next" [routerLink]="nextRouter">Next Form -></button>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #templateSave>
    <div class="modal-body text-center ">
        <div class="modal-header">
            <h4 class="modal-title pull-left" style="color: red;"> <i>Unsaved Changes !</i> </h4>
            <div class="text-right">
                <a (click)="alertClose()">
                    <img src="../../../../assets/form-icon/close-red-icon.png">
                </a>
            </div>
        </div>

        <div class="row mb-3 mt-3">
            <div class="col-sm-12">{{alertError}}
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 text-end">
                <button type="button" class="btn btn-light" (click)="discard()">Discard Changes</button>
                <button type="button" class="btn btn-primary m-btn ms-2" (click)="stay()">Stay</button>
                <button type="button" class="btn ms-2 m-btn" (click)="proceed()">
                Save & Proceed
              </button>
            </div>

        </div>

    </div>
</ng-template>

<div class="text-center p-4" *ngIf="isApiInProgress">
    <app-pre-loader-new></app-pre-loader-new>
</div>
