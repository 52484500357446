<div class="container-fluid" id="previewUti" #previewUti>
    <div class="row">
        <div class="col-sm-12" [ngClass]="{'header-p':!parentData, 'header-ut-p' : parentData }">
            <div class="row" style="margin: 0;">
                <div class="col-sm-12 text-r pdf-hide" *ngIf="!parentData" style="text-align: right;">
                    <!-- <mat-icon aria-hidden="false">
                      <img src="../../../../../assets/form-icon/Icon material-close.svg">
                  </mat-icon> -->
                    <i class="fa fa-times" style="color: #fff; cursor: pointer;
                  font-size: 24px; " aria-hidden="true" (click)="dialogClose()"></i>
                </div>
                <div class="col-sm-12 h-uti-p" [ngClass]="{'heading-p':!parentData, 'heading-ut-p' : parentData }">
                    <span>
                      GRANT UTILISATION REPORT FOR THE FIFTEENTH COMMISSION RECOMMENDED
                      TIED GRANT RECEIVED DURING THE AWARD PERIOD 2022-23 TO 2025-26.
                  </span>
                    <hr style="border: 1px solid #FFFFFF; margin: 5px 0;">
                </div>
                <div class="col-sm-12 text-center s-h-uti">
                    <span>
                      (to be submitted by NMPCs & MPCs as per details in para 14 above)
                  </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row se-r pd-row-n">
        <div class="col-sm-9 st-n">
            <span>Name of State:- <span>{{ state }}</span></span>
        </div>
        <div class="col-sm-3 pdf-hide" style="text-align: right;">
            <button *ngIf="!parentData" id="donwloadButton" style="height: 32px; right: 0%; outline: none;" class="btn btn-primary btn-upload" (click)="clickedDownloadAsPDF(template)">
              Download as PDF
          </button>
        </div>
        <div class="col-sm-12 st-n m-top">
            <span>
              Name of City/UA[MPC/NMPC]:-
              <span>{{ ulb }}</span>
            </span>
        </div>
    </div>
    <div class="row pd-row">
        <div class="col-sm-12 ff-table">
            <table class="table table-bordered border-primary f-table">
                <thead>
                    <tr>
                        <th class="f-table" scope="col">S. No.</th>
                        <th class="f-table" scope="col">Item description</th>
                        <th scope="col" class="pd-th f-table">Amount (Rs. in lakh)</th>
                        <th scope="col" class="pd-th f-table">% of total tied grant earmarked </th>
                        <th scope="col" class="pd-th f-table">No of projects undertaken</th>
                        <th scope="col" class="pd-th f-table">Total project cost involved (Rs. in lakh)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="f-table" scope="row">1.</th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500;">Unutilisated tied grant from previous installment</td>
                        <td class="f-table">{{data.grantPosition.unUtilizedPrevYr}}</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                    </tr>
                    <tr>
                        <th scope="row" class="f-table">2.</th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500;">15
                            <sup style="text-transform: capitalize">th</sup> F.C. Tied grant received during the year [ 1
                            <sup>st</sup> & 2<sup>nd</sup> installment taken together ]

                        </td>
                        <td class="f-table">{{data.grantPosition.receivedDuringYr}}</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                    </tr>
                    <tr>
                        <th class="f-table" scope="row">3.</th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500;">Expenditure incurred during the year</td>
                        <td class="f-table">{{data.grantPosition.expDuringYr}}</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                    </tr>
                    <tr>
                        <th class="f-table" scope="row">4.</th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500;"> Closing balance at the end of year</td>
                        <td class="f-table"><b>{{ data.grantPosition.closingBal }}</b> </td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                    </tr>
                    <tr>
                        <th scope="row" class="f-table">5.</th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500;"> Component-wise utilisation of grant </td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                    </tr>
                    <tr>
                        <th scope="row" class="f-table"></th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500;">A. &nbsp; &nbsp;Water Management(WM)
                        </td>
                        <!-- (((data?.analytics[2]?.amount + data?.analytics[3]?.amount +
                         data?.analytics[5]?.amount +data?.analytics[1]?.amount)/data.grantPosition.expDuringYr)*100).toFixed(2) -->

                        <td class="f-table" colspan="2" style="font-weight: bold;">Total % of tied grant utilised in WM [&nbsp;{{ +((totalWmAmount/data.grantPosition.expDuringYr)*100).toFixed(2) > 0 ? (+(totalWmAmount/data.grantPosition.expDuringYr)*100).toFixed(2) : 0 }}% ]
                        </td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                    </tr>
                    <tr *ngFor="let outputRow of data?.categoryWiseData_wm; let rowIndex=index">
                        <th scope="row" class="f-table"></th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500; white-space: nowrap;">
                            {{rowIndex+1}}. &nbsp; &nbsp; {{outputRow.category_name}}
                        </td>
                        <td class="f-table">{{outputRow.grantUtilised}}</td>
                        <td class="f-table">x</td>
                        <td class="f-table">{{outputRow.numberOfProjects}}</td>
                        <td class="f-table">{{outputRow.totalProjectCost}}</td>
                    </tr>
                    <!-- <tr>
                    <th scope="row"></th>
                    <td style="text-align: left; font-weight: 500;">i. &nbsp; &nbsp;Supply of Drinking water</td>
                    <td>{{data?.analytics[2]?.amount}}</td>
                    <td>x</td>
                    <td>{{data?.analytics[2]?.count}}</td>
                    <td>{{data?.analytics[2]?.totalProjectCost}}</td>
                </tr>
                  <tr>
                      <th scope="row"></th>
                      <td style="text-align: left; font-weight: 500;">ii. &nbsp; &nbsp;Rain water harvesting</td>
                      <td>{{data?.analytics[3]?.amount}}</td>
                      <td>x</td>
                      <td>{{data?.analytics[3]?.count}}</td>
                      <td>{{data?.analytics[3]?.totalProjectCost}}</td>
                  </tr>
                  <tr>
                      <th scope="row"></th>
                      <td style="text-align: left; font-weight: 500;">iii. &nbsp; &nbsp;Water Recycling</td>
                      <td>{{data?.analytics[5]?.amount}}</td>
                      <td>x</td>
                      <td>{{data?.analytics[5]?.count}}</td>
                      <td>{{data?.analytics[5]?.totalProjectCost}}</td>
                  </tr>
                  <tr>
                      <th scope="row"></th>
                      <td style="text-align: left; font-weight: 500;">iv. &nbsp; &nbsp;Rejenuvenation of water bodies</td>
                      <td>{{data?.analytics[1]?.amount}}</td>
                      <td>x</td>
                      <td>{{data?.analytics[1]?.count}}</td>
                      <td>{{data?.analytics[1]?.totalProjectCost}}</td>
                  </tr> -->
                    <tr>
                        <th scope="row" class="f-table"></th>
                        <!-- (((data?.analytics[0]?.amount +
                          data?.analytics[4]?.amount)/data.grantPosition.expDuringYr)*100).toFixed(2) -->
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500; white-space: nowrap;">B. &nbsp; &nbsp;Solid Waste Management(SWM)</td>
                        <td class="f-table" colspan="2" style="font-weight: bold;">Total % of tied grant utilised in SWM [&nbsp;{{+((totalSwmAmount/data.grantPosition.expDuringYr)*100).toFixed(2) >0 ? ((totalSwmAmount/data.grantPosition.expDuringYr)*100).toFixed(2) : 0 }}% ]
                        </td>
                        <td class="f-table">x</td>
                        <td class="f-table">x</td>
                    </tr>
                    <tr *ngFor="let outputRow of data?.categoryWiseData_swm; let rowIndex=index">
                        <th scope="row" class="f-table"></th>
                        <td class="pd-r f-table" style="text-align: left; font-weight: 500; white-space: nowrap;">
                            {{rowIndex+1}}. &nbsp; &nbsp; {{outputRow.category_name}}
                        </td>
                        <td class="f-table">{{outputRow.grantUtilised}}</td>
                        <td class="f-table">x</td>
                        <td class="f-table">{{outputRow.numberOfProjects}}</td>
                        <td class="f-table">{{outputRow.totalProjectCost}}</td>
                    </tr>
                    <!-- <tr>
                      <th scope="row"></th>
                      <td style="text-align: left; font-weight: 500;">i. &nbsp; &nbsp;Sanitation</td>
                      <td>{{data?.analytics[5]?.amount}}</td>
                      <td>x</td>
                      <td>{{data?.analytics[5]?.count}}</td>
                      <td>{{data?.analytics[5]?.totalProjectCost}}</td>
                  </tr>
                  <tr>
                      <th scope="row"></th>
                      <td style="text-align: left; font-weight: 500;">ii. &nbsp; &nbsp;Solid Waste Management</td>
                      <td>{{data?.analytics[4]?.amount}}</td>
                      <td>x</td>
                      <td>{{data?.analytics[4]?.count}}</td>
                      <td>{{data?.analytics[4]?.totalProjectCost}}</td>
                  </tr> -->
                </tbody>
            </table>
        </div>
    </div>
    <div class="row pd-row pj-tb">
        <div class="col-sm-12 se-tb ff-table">
            <table class="table table-bordered border-primary f-table tableStriped">
                <thead>
                    <tr style="border-collapse: separate;">
                        <th scope="col" colspan="9" style="border: none; text-align: center;">Project details</th>
                    </tr>
                </thead>
                <tbody></tbody>
                <thead>
                    <tr>
                        <th scope="col" class="f-table" style="text-decoration: underline;">S. No.</th>
                        <th scope="col" class="f-table">Name of Project</th>
                        <th scope="col" class="f-table">Sector (Water Management /Sanitation/ SWM)</th>
                        <th scope="col" class="f-table" colspan="2" style="text-align: center;">
                            <span>Location</span>
                            <!-- <hr>
                          <span style="border-right: 1px solid black;">Lat</span>
                          <span>Long</span> -->
                        </th>
                        <!-- <th scope="col" class="f-table">Engineer's Name</th> -->
                        <!-- <th scope="col" class="f-table">Engineer's Contact No.</th> -->
                        <th scope="col" class="pd-th f-table">Total Project cost</th>
                        <th scope="col" class="pd-th f-table">% of 15<sup>th</sup> FC grants in total project cost

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data?.projects; let i=index" style="text-align: center; vertical-align: inherit;">
                        <th scope="row" class="f-table">{{1+i}}.</th>
                        <td class="f-table">{{data.projects[i].name}}</td>
                        <td class="f-table">{{data.projects[i].categoryName}}</td>
                        <td class="f-table p-tb">{{data.projects[i].location.lat}}</td>
                        <td class="f-table p-tb">{{data.projects[i].location.long}}</td>
                        <!-- <td class="f-table p-tb">{{data.projects[i]?.engineerName}}</td> -->
                        <!-- <td class="f-table p-tb">{{data.projects[i]?.engineerContact}}</td> -->
                        <td class="f-table">{{data.projects[i].cost}}</td>
                        <td class="f-table">
                            {{((data?.projects[i].expenditure/data?.projects[i].cost)*100).toFixed(2) >=0? ((data?.projects[i].expenditure/data?.projects[i].cost)*100).toFixed(2) : 0 }}%
                        </td>
                        <!-- <td>{{data.projects[i].expenditure}}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row pd-row" style="margin-top: 1%">
        <div class="col-sm-12 f-text">
            <span>
              Certified that the above information has been extracted from the relevant Records being maintained
              in the ULB and is true to the best of my knowledge and belief.
          </span>
        </div>
    </div>
    <div class="row pd-row name-row">
        <div class="col-sm-12">
            Name : {{data.name}}
        </div>
        <div class="col-sm-12">
            Designation : {{data.designation}}
        </div>
    </div>

    <div class="row m-b pd-row">
        <div class="col-sm-12 sig-text text-center">
            <span>
              Signature with seal of commissioner or appropriate Authority in the ULB
          </span>
        </div>
    </div>
</div>

<ng-template #templateSave>
    <div class="modal-body text-center alert">
        <div class="modal-header">
            <h4 class="modal-title pull-left" style="color: red;"> <i>Alert!</i> </h4>
            <div class="text-right">
                <a (click)="alertClose()">
                    <img src="../../../../assets/form-icon/close-red-icon.png">
                </a>
            </div>
        </div>

        <p style="margin-top: 8px;">
            You have some unsaved changes on this page. Do you wish to save your data as draft?
        </p>
        <div class="row">
            <div class="col-sm-12 text-end">
                <button type="button" class="btn btn-secondary " (click)="stay()">Stay</button>
                <button type="button" class="btn btn-success " style="margin-left: 6px; width: auto; background-color: #5cb85c" (click)="proceed()">
            Save & Proceed</button>
            </div>

        </div>
    </div>
</ng-template>
