<div id="previewSlb" #previewSlb>
    <div style="background: #047474; color: white; position: relative; text-align: center;" [ngClass]="{'slb-pd':!preData?.fromParent, 'slb-pd-t' : preData?.fromParent }">
        <span class="heading-font" style="margin-top: 0px; margin-bottom: 0;">
          Submissions for 15th FC grant for FY 2021-22</span>
        <h3 class="sub-h-font" style="text-align: center; margin-top: 3px; margin-bottom: 0;">
            {{ ulbName }}, {{stateName}}
        </h3>
        <i style="position: absolute; right: 3%; top: 14%; transform: scale(1.5);cursor: pointer;" class="fa fa-times" aria-hidden="true" (click)="_matDialog.closeAll()"></i>
    </div>

    <div class="d-flex justify-content-between mt-2" style="padding-left: 2%">
        <p class="form-status"></p>
        <button id="donwloadButton" style="right: 0%; border-color: unset; outline: none; margin-right: 2%" class="btn btn-primary btn-upload pdf-hide" (click)="clickedDownloadAsPDF(template)">
      Download as PDF
    </button>
    </div>
    <div style="position: relative; padding: 2% 2%" class="">
        <h3 style="text-align: center; margin-top: 5px; margin-bottom: 8px;">{{slbTitleText}}</h3>
        <table aria-describedby="Water Waste Management Form" class="t-tb-tr tb-th-s dType" style="margin-bottom: 5%; width: 100%; border: 1px solid #dedede" cellspacing="0" cellpadding="0">
            <thead style="color: #050808" class="tb-th-s">
                <th style="padding: 1%" scope="col" class="tb-th-s">Service Level Indicators</th>
                <th style="padding: 1%; text-align: center" class="tb-th-s" scope="col">Benchmark</th>
                <th style="padding: 1%; text-align: center" class="tb-th-s" scope="col">
                    Actual Indicator <br /> 2020-21
                </th>
                <th style="padding: 1%; text-align: center" class="tb-th-s" *ngFor="let column of targets" scope="col" [innerHTML]="column.name"></th>
                <th *ngFor="let column of achieved" scope="col" style="padding: 1%; text-align: center">
                    <span style="padding: 0;" [innerHTML]="column.name"></span>
                </th>
                <th scope="col" style="padding: 1%; text-align: center"> Score</th>
            </thead>
            <tbody class="t-tb-tr tb-th-s">
                <tr *ngIf="services[0] as service" class="t-tb-tr pdd-tr">
                    <td class="td-width tb-th-s">
                        {{ service.name }}
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ service.benchmark }}</span>
                    </td>
                    <td style="text-align: center" class="tb-th-s">
                        <span *ngIf="preData?.waterManagement[service.key]?.baseline">
              {{ preData?.waterManagement[service.key]?.baseline["2021"] }}
            </span>
                        <em class="glyphicon" *ngIf="
                        preData?.waterManagement[service.key]?.baseline &&
                        preData?.waterManagement[service.key]?.baseline['2021']
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </em>
                    </td>
                    <td style="text-align: center" class="tb-th-s" *ngFor="let year of targets">
                        <span *ngIf="preData?.waterManagement[service.key]?.target">
              {{ preData?.waterManagement[service.key]?.target[year.key] }}
            </span>
                        <em class="glyphicon" *ngIf="
                        preData?.waterManagement[service.key]?.target &&
                        preData?.waterManagement[service.key]?.target[year.key]
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </em>
                    </td>
                    <td *ngFor="let year of achieved;  index as i" style="text-align: center;" class="tb-th-s">
                        <span *ngIf="preData?.waterManagement[service.key]?.baseline">
                        {{ preData?.waterManagement?.waterSuppliedPerDay?.achieved['2122']}}
                      </span>
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.waterSuppliedPerDay?.score['2122']}}</span>
                    </td>
                </tr>
                <!-- <tr *ngIf="
                preData?.waterManagement[services[0].key].status ===
              uploadSTATUS.REJECTED
            " class="t-tb-tr">
                    <td></td>
                    <td></td>
                    <td colspan="5" class="tb-th-s">
                        <h5 style="margin-bottom: 0px">Reason for rejection</h5>
                        {{ preData?.waterManagement[services[0].key].rejectReason }}
                    </td>
                    <td *ngFor="let year of achieved;  index as i" style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.reduction?.achieved['2122']}}</span>
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.reduction?.score['2122']}}</span>
                    </td>
                </tr> -->

                <tr *ngIf="services[1] as service" class="t-tb-tr pdd-tr">
                    <td style="" class="td-width tb-th-s">
                        {{ service.name }}
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ service.benchmark }}</span>
                    </td>


                    <td style="text-align: center" class="tb-th-s">
                        <span *ngIf="preData?.waterManagement[service.key]?.baseline">
              {{ preData?.waterManagement[service.key]?.baseline["2021"] }}
            </span>
                        <em class="glyphicon" style="font-weight: 600" *ngIf="
                        preData?.waterManagement[service.key]?.baseline &&
                        preData?.waterManagement[service.key]?.baseline['2021']
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </em>
                    </td>
                    <td style="text-align: center" class="tb-th-s" *ngFor="let year of targets">
                        <span *ngIf="preData?.waterManagement[service.key]?.target">
              {{ preData?.waterManagement[service.key]?.target[year.key] }}
            </span>

                        <i class="glyphicon" style="font-weight: 600" *ngIf="
                        preData?.waterManagement[service.key]?.target &&
                        preData?.waterManagement[service.key]?.target[year.key]
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </i>
                    </td>
                    <td *ngFor="let year of achieved;  index as i" style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.reduction?.achieved['2122'] }}</span>
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.reduction?.score['2122'] }}</span>
                    </td>
                </tr>
                <!-- <tr *ngIf="
                preData?.waterManagement[services[1].key].status ===
              uploadSTATUS.REJECTED
            " class="t-tb-tr">
                    <td></td>
                    <td></td>
                    <td colspan="5" style="padding-left: 1%">
                        <h5 style="margin-bottom: 0px">Reason for rejection</h5>
                        {{ preData?.waterManagement[services[1].key].rejectReason }}
                    </td>
                </tr> -->

                <tr *ngIf="services[2] as service" class="t-tb-tr pdd-tr">
                    <td class="td-width tb-th-s">
                        {{ service.name }}
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ service.benchmark }}</span>
                    </td>


                    <td style="text-align: center" class="tb-th-s">
                        <span *ngIf="preData?.waterManagement[service.key]?.baseline">
              {{ preData?.waterManagement[service.key]?.baseline["2021"] }}
            </span>
                        <em class="glyphicon" style="font-weight: 600" *ngIf="
                        preData?.waterManagement[service.key]?.baseline &&
                        preData?.waterManagement[service.key]?.baseline['2021']
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </em>
                    </td>
                    <td style="text-align: center" class="tb-th-s" *ngFor="let year of targets">
                        <span *ngIf="preData?.waterManagement[service.key]?.target">
              {{ preData?.waterManagement[service.key]?.target[year.key] }}
            </span>

                        <em class="glyphicon" style="font-weight: 600" *ngIf="
                        preData?.waterManagement[service.key]?.target &&
                        preData?.waterManagement[service.key]?.target[year.key]
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </em>
                    </td>
                    <td *ngFor="let year of achieved;  index as i" style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.houseHoldCoveredWithSewerage?.achieved['2122']}}</span>
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.houseHoldCoveredWithSewerage?.score['2122'] }}</span>
                    </td>
                </tr>
                <!-- <tr *ngIf="
                preData?.waterManagement[services[2].key].status ===
              uploadSTATUS.REJECTED
            " class="t-tb-tr">
                    <td></td>
                    <td></td>
                    <td colspan="5" style="padding-left: 1%" class="tb-th-s">
                        <h5 style="margin-bottom: 0px">Reason for rejection</h5>
                        {{ preData?.waterManagement[services[2].key].rejectReason }}
                    </td>
                </tr> -->

                <tr *ngIf="services[3] as service" class="t-tb-tr pdd-tr">
                    <td class="td-width tb-th-s">
                        {{ service.name }}
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ service.benchmark }}</span>
                    </td>


                    <td style=" text-align: center" class="tb-th-s">
                        <span *ngIf="preData?.waterManagement[service.key]?.baseline">
              {{ preData?.waterManagement[service.key]?.baseline["2021"] }}
            </span>
                        <em class="glyphicon" style="font-weight: 600" *ngIf="
                        preData?.waterManagement[service.key]?.baseline &&
                        preData?.waterManagement[service.key]?.baseline['2021']
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </em>
                    </td>
                    <td style=" text-align: center" class="tb-th-s" *ngFor="let year of targets">
                        <span *ngIf="preData?.waterManagement[service.key]?.target">
              {{ preData?.waterManagement[service.key]?.target[year.key] }}
            </span>

                        <em class="glyphicon" style="font-weight: 600" *ngIf="
                        preData?.waterManagement[service.key]?.target &&
                        preData?.waterManagement[service.key]?.target[year.key]
                ">
              {{ service.key === "waterSuppliedPerDay" ? "LPCD" : "%" }}
            </em>
                    </td>
                    <td *ngFor="let year of achieved;  index as i" style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.houseHoldCoveredPipedSupply?.achieved['2122'] }}</span>
                    </td>
                    <td style="text-align: center;" class="tb-th-s">
                        <span class="benchamark-cell">{{ preData?.waterManagement?.houseHoldCoveredPipedSupply?.score['2122'] }}</span>
                    </td>
                </tr>
                <!-- <tr *ngIf="
                preData?.waterManagement[services[3].key].status ===
              uploadSTATUS.REJECTED
            " class="t-tb-tr">
                    <td></td>
                    <td></td>
                    <td colspan="5" style="padding-left: 1%" class="tb-th-s">
                        <h5 style="margin-bottom: 0px">Reason for rejection</h5>
                        {{ preData?.waterManagement[services[3].key].rejectReason }}
                    </td>
                </tr> -->
            </tbody>
        </table>
    </div>
</div>