enum BASE_STATUS {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

enum UPLOAD_STATUS {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",

  NA = "NA",
}

const REQUEST_STATUS = { ...BASE_STATUS, CANCELLED: "CANCELLED" };
const ULBSIGNUPSTATUS = BASE_STATUS;

export { UPLOAD_STATUS, REQUEST_STATUS, ULBSIGNUPSTATUS };
