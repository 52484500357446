<div class="row mb-3">
    <div class="col-sm-5 q-n" style="width: 46%;">
        {{quesName}}<span class="text-danger" *ngIf="quesName != 'Supporting Document :'">*</span>
        <span *ngIf="itemObj?.tooltip != '' && quesName != 'Supporting Document :'" matTooltip="{{itemObj?.tooltip}}" matTooltipClass="tooltip-red" matTooltipPosition="above">
          <img src="../../../../assets/ticks/info_black_18dp.svg">
        </span>

    </div>
    <div class="col-sm-4" style="width: 29%;">
        <div class="" *ngIf="quesType == 'input'">
            <!-- <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">₹</span>
            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
          </div> -->
            <div class="input-group">
                <span class="input-group-text" id="basic-addon1">₹</span>
                <input type="text" [disabled]="isDisabled" class="form-control" [ngClass]="{'err-i' : amountObj.error}" (keyup)="amountKeyUp('click')" [(ngModel)]="amountObj.value" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1" appFiftTwoDigitNumber>
            </div>
            <div class="mt-2">
                <!-- <span>{{amountObj.value | numberToWordINR}}</span> -->
                <!-- <span>{{amount1Type}}</span> -->
                <span>{{amount2Type}}</span>
                <span *ngIf="amountObj.error" class="err-text">Only 15 digits with 2 decimal points number are allowed.</span>
                <span *ngIf="zeroError" class="err-text">Zero not allowed.</span>
            </div>
        </div>
        <div class="" *ngIf="quesType == 'file'">
            <button *ngIf="showPdf" [disabled]="isDisabled" (click)="bs_pdf1.click()" class="btn btn-primary">Upload PDF
              <sup><span class="wh-s" *ngIf="quesName != 'Supporting Document :'">*</span></sup>
               <img src="../../../../assets/form-icon/upload-pdf-icon.png" alt="" style="margin-left: 4px;">
           </button>
            <input #bs_pdf1 style="display: none;" onclick="this.value=null;" (change)="fileChangeEvent($event,'pdf')" type="file" accept=".pdf" #file>
            <button *ngIf="showExcel" [disabled]="isDisabled" (click)="bs_excel1.click()" class="btn btn-primary ms-2">Upload
                   EXCEL
                 <span class="iconify xls" data-icon="mdi-file-excel" data-inline="false" data-width="23"
                   data-height="16"></span>
             </button>
            <input #bs_excel1 style="display: none;" onclick="this.value=null;" (change)="fileChangeEvent($event,'excel')" type="file" accept=".xls, .xlsx" #file>
        </div>
        <div *ngIf="itemError" class="" style="">
            <label *ngIf="quesType == 'file'" class='text-danger' style="margin-right: 5px;">
             {{pdfError}}
           </label>
            <label *ngIf="quesType == 'input'" class='text-danger' style="margin-right: 5px;">
            {{inputNumberError}}
          </label>
        </div>
    </div>
    <!-- action buttons -->
    <!-- <div class="col-sm-3 d-none" style="width: 25%" *ngIf="quesType == 'file'">
        <button type="button" class="btn btn-success ">Approve</button>
        <button type="button" class="btn btn-danger ms-3">Retrun</button>
        <textarea class="mt-3" name="reason" id="reason" cols="25" rows="2"></textarea>
    </div> -->
</div>
<div class="row" *ngIf="(quesType == 'file') && (data.excel?.name || data.pdf?.name)">
    <div class="col-sm-9">
        <div class="row allupload mb-3" *ngIf="showPdf">
            <div class="col-sm-5">
                <label for="" class="pdf-n" style="" *ngIf="data.pdf?.name">
                      {{data.pdf?.name}}
                </label>
            </div>
            <div class="col-sm-4 ms-3" *ngIf="data.pdf?.progress" style="padding: 0;">
                <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ data.pdf?.progress }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: data.pdf?.progress + '%' }">
                    {{ data.pdf?.progress }}%
                </div>
            </div>

            <div class="col-sm-2 ms-3" style="padding: 0;">
                <ng-container>
                    <div *ngIf="data.pdf?.url">
                        <a href="{{data.pdf?.url | toStorageUrl}}" target="_blank">
                            <img src="../../../../assets/form-icon/download-pdf-icon.png" alt="" style="margin-left: 1px; height: 19px;">

                        </a>
                        <a [ngClass]="{cross: isDisabled}" (click)="clearFile('pdf', 'click')" style="margin-left: 3px;">
                            <img src="../../../../assets/form-icon/close-red-icon.png" alt="" style="height: 13px;">
                        </a>
                    </div>
                    <div *ngIf="data.pdf?.error">
                        <a>
                            <img src="../../../../assets/form-icon/warning.png" alt="" style="margin-left: 1px; height: 19px;">
                        </a>
                        <a [ngClass]="{cross: isDisabled}" (click)="fileChangeEvent(true,'pdf')" style="margin-left: 3px;">
                            <img src="../../../../assets/form-icon/refresh.png" alt="" style="height: 13px;">
                        </a>
                    </div>

                </ng-container>
            </div>
        </div>
        <div class="row allupload mb-3" *ngIf="showExcel">
            <div class="col-sm-5">
                <label for="" class="pdf-n" style="" *ngIf="data.excel?.name">
                      {{data.excel?.name}}
                </label>
            </div>
            <div class="col-sm-4 ms-3" *ngIf="data.excel?.progress" style="padding: 0;">
                <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{ data.excel?.progress }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: data.excel?.progress + '%' }">
                    {{ data.excel?.progress }}%
                </div>
            </div>
            <div class="col-sm-2 ms-3" style="padding: 0;">
                <ng-container>
                    <div *ngIf="data.excel?.url">
                        <a href="{{ data.excel?.url | toStorageUrl}}" target="_blank">
                            <img src="../../../../assets/form-icon/download-pdf-icon.png" alt="" style="margin-left: 1px; height: 19px;">

                        </a>
                        <a [ngClass]="{cross: isDisabled}" (click)="clearFile('excel', 'click')" style="margin-left: 3px;">
                            <img src="../../../../assets/form-icon/close-red-icon.png" alt="" style="height: 13px;">
                        </a>
                    </div>
                    <div *ngIf="data.excel?.error">
                        <a>
                            <img src="../../../../assets/form-icon/warning.png" alt="" style="margin-left: 1px; height: 19px;">
                        </a>
                        <a [ngClass]="{cross: isDisabled}" (click)="fileChangeEvent(true,'excel')" style="margin-left: 3px;">
                            <img src="../../../../assets/form-icon/refresh.png" alt="" style="height: 13px;">
                        </a>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
    <div class="col-sm-3">

    </div>
</div>
