<div class="row" [ngSwitch]="errorType">
    <div class="col-sm-12" *ngSwitchCase="'normal'">
        <div *ngIf="formControlSec.errors?.required">
            {{fName}} is required.
        </div>
        <!-- <div *ngIf="formControlSec.errors['minlength']">
            Name must be at least 4 characters long.
        </div> -->
        <div *ngIf="formControlSec.errors['maxlength']">
            Maximum 50 Characters are allowed*
        </div>
        <div *ngIf="formControlSec.errors['pattern']">
            Maximum six digit upto two decimals point are allowed. eg - 999999.99

        </div>
    </div>
    <div class="col-sm-12" *ngSwitchCase="'table'">
        <div *ngIf="formControlSec.errors?.required">
            Error
            <span class="material-icons" tooltip="{{fName}} is required." style="vertical-align: middle;">
              info
            </span>
        </div>
        <div *ngIf="formControlSec.errors['pattern']">
            Error
            <span class="material-icons" [tooltip]="patternError" style="vertical-align: middle;">
           info
          </span>
            <!-- Two digit with six decimals point are allowed.eg - 28.123456 -->
        </div>
        <div *ngIf="formControlSec.errors['maxlength']">
            Maximum 50 Characters are allowed*
        </div>
    </div>
</div>
