
export const staticFileKeys = {
    "ANNUAL_ACCOUNT_2023_24": 1,
    "ANNUAL_ACCOUNT_2022_23": 2,
    "UPLOAD_ANNUAL_ACCOUNTS_ICON": 3,
    "FR_LANDING_PAGE_GUIDELINES": 4,
    "FR_LANDING_PAGE_BROCHURE": 5,
    "FR_LANDING_PAGE_LATEST_VIDEO": 6,
    "FR_LANDING_PAGE_LAUNCH_VIDEO": 7,
    "FR_LANDING_PAGE_OLD_CONTACT_DETAILS": 8,
    "VADODARA_MUNICIPAL_CORPORATION_A_MINUS": 9,
    "VADODARA_MUNICIPAL_CORPORATION_A_PLUS": 10,
    "STANDARDIZATION_PROCESS_OF_ANNUAL_FINANCIAL_STATEMENT_OF_ULBS": 11,
    "FR_LANDING_PAGE_DRAFT_GUIDELINES": 12,

}