export enum ACTIONS {
  CREATE = "CREATE",
  EDIT = "EDIT",
  VIEW = "VIEW",
  DELETE = "DELETE",
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  DOWNLOAD = "DOWNLOAD",
  UPLOAD = "UPLOAD",
  FORM_FILL = "FORM_FILL",
}
