import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-odf',
  templateUrl: './odf.component.html',
  styleUrls: ['./odf.component.scss']
})
export class OdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
