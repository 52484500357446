<div class="testing"></div>

<div class="col-sm-12 stateMapContainer">
  <div class="selectedEntity">
    <span
      *ngIf="currentStateInView && currentStateInView.name"
      style="display: inline-block; min-width: fit-content;"
    >
      {{ currentStateInView ? currentStateInView.name : ""
      }}<span *ngIf="currentULBClicked">:&nbsp; </span>
    </span>
    <span *ngIf="currentULBClicked">
      {{ currentULBClicked.name }}
    </span>
  </div>
  <!-- <p *ngIf="currentStateInView" class="text-center" style="width: 100%;">
    Click on any ULB to view their data or click on INDIA map to go back.
  </p> -->
  <div *ngIf="!nationalLevelMap" class="skeletonWrapper">
    <app-pre-loader></app-pre-loader>
    <app-pre-loader></app-pre-loader>
    <app-pre-loader></app-pre-loader>
    <app-pre-loader></app-pre-loader>
  </div>
  <div class="ulbSelectDropdown">
    <section
      class="regionalDetails regionalDetails2 col-sm-12"
      *ngIf="mouseHoverOnState"
    >
      <div
        style="
          align-items: center;
          display: inline-flex;
          width: fit-content;
          position: relative;
          justify-content: center;
          margin-right: 5%;
        "
      >
        <span style="position: absolute; font-size: 0.7em;">
          {{ mouseHoverOnState.coveredUlbPercentage | number: "1.2-2" }} %
        </span>
        <mat-progress-spinner
          [value]="mouseHoverOnState.coveredUlbPercentage | number: '2.2-2'"
          [diameter]="50"
        ></mat-progress-spinner>
        <mat-progress-spinner
          style="position: absolute;"
          class="unfilled"
          [value]="100"
          [diameter]="50"
        ></mat-progress-spinner>
      </div>

      <div style="align-items: center; display: inline; width: fit-content;">
        <span><strong>Data Availability</strong></span
        ><br />
        <span> {{ mouseHoverOnState.name }}</span>
        <br />
        <span>
          <span class="colorText">
            {{ mouseHoverOnState.coveredUlbCount }}
          </span>
          out of
          <span class="colorText">
            {{ mouseHoverOnState.totalUlbs }}
          </span>
        </span>
        ULBs
        <br />
        <span class="colorText"> {{ mouseHoverOnState.audited }}</span> ULBs
        Audited
      </div>
    </section>

    <section
      class="regionalDetails ulbDetails col-sm-12"
      *ngIf="mouseHoveredOnULB"
    >
      <span class="col-sm-12"
        ><span class="bold">ULB:</span> {{ mouseHoveredOnULB.name }}</span
      >
      <span class="col-sm-12">
        <span class="bold">Area:</span>
        {{ mouseHoveredOnULB.area | rupeeConverter }} sq. km
      </span>
      <span class="col-sm-12">
        <span class="bold">
          Population:
        </span>
        {{ mouseHoveredOnULB.population | rupeeConverter }}
      </span>
      <span class="col-sm-12">
        <span class="bold">
          Audit Status:
        </span>
        <span class="text-capitalize">
          {{ mouseHoveredOnULB.auditStatus }}</span
        >
      </span>
    </section>
  </div>
  <div
    class="col-sm-12"
    id="districtMapContainer"
    [ngStyle]="{ visibility: currentStateInView ? 'visible' : 'hidden' }"
  >
    <!-- <p class="text-center" *ngIf="currentStateInView">
      Click on any ULB to view their data or Click on INDIA map to go back.
    </p> -->
  </div>

  <div [id]="'mapidd' + randomNumber" class="stateMap analytics-map"></div>
</div>
<p
  class="text-center national-map-click-guide"
  *ngIf="!currentStateInView && nationalLevelMap"
>
  Click on any state to view state and ULB related data
</p>
