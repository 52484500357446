import { USER_TYPE } from 'src/app/models/user/userType';

import { ACTIONS } from './actions';

export enum MODULES_NAME {
  FINANCIAL_DATA_UPLOAD = "FINANCIAL_DATA_UPLOAD",
  MoHUA = "MoHUA",
  OVERALL_REPORT = "OVERALL_REPORT",

  PARTNER = "PARTNER",
  PROPERTY_TAX_QUESTIONNAIRE_LIST = "PROPERTY_TAX_QUESTIONNAIRE_LIST",
  REPORTS = "REPORTS",
  STATE_PROPERTY_TAX_QUESTIONNAIRE = "PROPERTY_TAX_QUESTIONNAIRE",
  STATE_WISE_REPORT = "STATE_WISE_REPORT",
  STATE = "STATE",

  SELF_PROFILE = "SELF_PROFILE",
  TABLE_DOWNLOAD = "TABLE_DOWNLOAD",
  USER = "USER",
  USERLIST = "USERLIST",
  ULB_SIGNUP_REQUEST = "ULB_SIGNUP_REQUEST",

  ULB = "ULB",
  ULB_DATA_UPLOAD = "ULB_DATA_UPLOAD",
  ULBDataBULKEntry = "ULBDataBULKEntry",
  ULB_TYPE_WISE_REPORT = "ULB_TYPE_WISE_REPORT",
  STATE_AND_ULB_TYPE_WISE_REPORT = "STATE_AND_ULB_TYPE_WISE_REPORT",
  USAGE_REPORT = "USAGE_REPORT",
  ULB_LEVEL_PROPERTY_TAX_QUESTIONNAIRE = "ULB_LEVEL_PROPERTY_TAX_QUESTIONNAIRE",
}

export interface IModules {
  name: MODULES_NAME;
  access: {
    [key in ACTIONS]?: USER_TYPE[];
  };
  // subModules?: { [key in MODULES_NAME]: IModules };
}

export const MODULES: { [key in MODULES_NAME]: IModules } = {
  [MODULES_NAME.FINANCIAL_DATA_UPLOAD]: {
    name: MODULES_NAME.FINANCIAL_DATA_UPLOAD,
    access: {
      [ACTIONS.VIEW]: [
        USER_TYPE.ADMIN,
        USER_TYPE.MoHUA,
        USER_TYPE.PARTNER,
        USER_TYPE.STATE,
        USER_TYPE.ULB,
      ],
      [ACTIONS.CREATE]: [USER_TYPE.ULB],
    },
  },
  [MODULES_NAME.TABLE_DOWNLOAD]: {
    name: MODULES_NAME.TABLE_DOWNLOAD,
    access: {
      [ACTIONS.DOWNLOAD]: [
        USER_TYPE.USER,
        USER_TYPE.ULB,
        USER_TYPE.STATE,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
    },
  },

  [MODULES_NAME.ULB_DATA_UPLOAD]: {
    name: MODULES_NAME.ULB_DATA_UPLOAD,
    access: {
      [ACTIONS.VIEW]: [
        USER_TYPE.ULB,
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.UPLOAD]: [USER_TYPE.ULB],
      [ACTIONS.APPROVE]: [USER_TYPE.STATE, USER_TYPE.MoHUA],
      [ACTIONS.REJECT]: [USER_TYPE.STATE, USER_TYPE.MoHUA],
    },

    /**
     * Currently I a User can approve the request, then he can also reject  the request.
     * There is no need to make a seperate acess for these.
     */
  },

  [MODULES_NAME.ULB_SIGNUP_REQUEST]: {
    name: MODULES_NAME.ULB_SIGNUP_REQUEST,
    access: {
      [ACTIONS.VIEW]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.APPROVE]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.REJECT]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.DELETE]: [USER_TYPE.ADMIN],
    },
  },

  [MODULES_NAME.ULB]: {
    name: MODULES_NAME.ULB,
    access: {
      [ACTIONS.VIEW]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.APPROVE]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.REJECT]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.EDIT]: [
        USER_TYPE.ADMIN,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.STATE,
        USER_TYPE.ULB,
      ],
      // [ACTIONS.  DELETE]: [USER_TYPE.ADMIN],
    },
  },

  [MODULES_NAME.SELF_PROFILE]: {
    name: MODULES_NAME.SELF_PROFILE,
    access: {
      [ACTIONS.EDIT]: [
        USER_TYPE.USER,
        USER_TYPE.ULB,
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
      ],
      [ACTIONS.VIEW]: [
        USER_TYPE.USER,
        USER_TYPE.ULB,
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
      ],
    },
  },

  [MODULES_NAME.USER]: {
    name: MODULES_NAME.USER,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.PARTNER, USER_TYPE.ADMIN, USER_TYPE.MoHUA],
      [ACTIONS.EDIT]: [USER_TYPE.USER],
      [ACTIONS.DELETE]: [USER_TYPE.ADMIN],
    },
  },
  [MODULES_NAME.USERLIST]: {
    name: MODULES_NAME.USERLIST,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.PARTNER, USER_TYPE.ADMIN, USER_TYPE.MoHUA],
    },
  },

  [MODULES_NAME.PARTNER]: {
    name: MODULES_NAME.PARTNER,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.ADMIN, USER_TYPE.MoHUA],
      [ACTIONS.CREATE]: [USER_TYPE.ADMIN, USER_TYPE.MoHUA],
      [ACTIONS.EDIT]: [USER_TYPE.ADMIN, USER_TYPE.MoHUA],
      [ACTIONS.DELETE]: [USER_TYPE.ADMIN],
    },
  },

  [MODULES_NAME.ULBDataBULKEntry]: {
    name: MODULES_NAME.ULBDataBULKEntry,
    access: {
      [ACTIONS.UPLOAD]: [USER_TYPE.PARTNER, USER_TYPE.MoHUA, USER_TYPE.ADMIN],
    },
  },

  [MODULES_NAME.STATE]: {
    name: MODULES_NAME.STATE,
    access: {
      [ACTIONS.CREATE]: [USER_TYPE.PARTNER, USER_TYPE.MoHUA, USER_TYPE.ADMIN],
      [ACTIONS.VIEW]: [USER_TYPE.PARTNER, USER_TYPE.MoHUA, USER_TYPE.ADMIN],
      [ACTIONS.EDIT]: [USER_TYPE.PARTNER, USER_TYPE.MoHUA, USER_TYPE.ADMIN],
      [ACTIONS.DELETE]: [USER_TYPE.ADMIN],
    },
  },

  [MODULES_NAME.MoHUA]: {
    name: MODULES_NAME.MoHUA,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.ADMIN],
      [ACTIONS.CREATE]: [USER_TYPE.ADMIN],
      [ACTIONS.EDIT]: [USER_TYPE.ADMIN],
      [ACTIONS.DELETE]: [USER_TYPE.ADMIN],
    },
  },
  [MODULES_NAME.REPORTS]: {
    name: MODULES_NAME.REPORTS,
    access: {
      [ACTIONS.VIEW]: [
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
        USER_TYPE.STATE,
      ],
      [ACTIONS.DOWNLOAD]: [
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
        USER_TYPE.PARTNER,
        USER_TYPE.STATE,
      ],
    },
  },

  [MODULES_NAME.OVERALL_REPORT]: {
    name: MODULES_NAME.OVERALL_REPORT,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.ADMIN, USER_TYPE.MoHUA, USER_TYPE.PARTNER],
    },
  },
  [MODULES_NAME.STATE_WISE_REPORT]: {
    name: MODULES_NAME.OVERALL_REPORT,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.ADMIN, USER_TYPE.MoHUA, USER_TYPE.PARTNER],
    },
  },
  [MODULES_NAME.ULB_TYPE_WISE_REPORT]: {
    name: MODULES_NAME.OVERALL_REPORT,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.ADMIN, USER_TYPE.MoHUA, USER_TYPE.PARTNER],
    },
  },
  [MODULES_NAME.STATE_AND_ULB_TYPE_WISE_REPORT]: {
    name: MODULES_NAME.OVERALL_REPORT,
    access: {
      [ACTIONS.VIEW]: [
        USER_TYPE.ADMIN,
        USER_TYPE.MoHUA,
        USER_TYPE.PARTNER,
        USER_TYPE.STATE,
      ],
    },
  },
  [MODULES_NAME.USAGE_REPORT]: {
    name: MODULES_NAME.OVERALL_REPORT,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.ADMIN, USER_TYPE.MoHUA, USER_TYPE.PARTNER],
    },
  },

  [MODULES_NAME.STATE_PROPERTY_TAX_QUESTIONNAIRE]: {
    name: MODULES_NAME.STATE_PROPERTY_TAX_QUESTIONNAIRE,
    access: {
      [ACTIONS.FORM_FILL]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
      [ACTIONS.VIEW]: [
        USER_TYPE.STATE,
        USER_TYPE.PARTNER,
        USER_TYPE.MoHUA,
        USER_TYPE.ADMIN,
      ],
    },
  },

  [MODULES_NAME.ULB_LEVEL_PROPERTY_TAX_QUESTIONNAIRE]: {
    name: MODULES_NAME.ULB_LEVEL_PROPERTY_TAX_QUESTIONNAIRE,
    access: {
      [ACTIONS.FORM_FILL]: [],
      [ACTIONS.VIEW]: [],
    },
  },

  [MODULES_NAME.PROPERTY_TAX_QUESTIONNAIRE_LIST]: {
    name: MODULES_NAME.PROPERTY_TAX_QUESTIONNAIRE_LIST,
    access: {
      [ACTIONS.VIEW]: [USER_TYPE.PARTNER, USER_TYPE.MoHUA, USER_TYPE.ADMIN],
    },
  },
};
