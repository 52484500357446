export enum USER_TYPE {
  "USER" = "USER",
  "ULB" = "ULB",
  "ADMIN" = "ADMIN",
  "MoHUA" = "MoHUA",
  "STATE" = "STATE",
  "XVIFC_STATE" = "XVIFC_STATE",
  "XVIFC" = "XVIFC",
  "PARTNER" = "PARTNER",
  "PMU" = "PMU"
}
